let i = 0

const sports = {
  'en': ['boxing', 'climbing', 'fitness', 'martial_arts', 'training'],
  'fr': ['en boxe 🥊', "d'escalade 🧗‍♀️", 'en fitness 🤸', "d'arts martiaux 🥋", "d'entraînement"]
}

const locale = document.getElementById('locale').dataset.locale

var videoUrls = {};
sports['en'].forEach(function(sport) {
  videoUrls[sport] = document.getElementById(sport + 'VideoPath').dataset.path;
});

const setNewSport = () => {
  if (document.getElementById('cover') === null) {
    return;
  }
  const sport = document.querySelector('.sports');
  const compStyles = window.getComputedStyle(sport);
  const animation = compStyles.getPropertyValue('animation');
  const animationTime = parseFloat(animation.match(/\d*[.]?\d+/)) * 1000;

  const sportEn = sports['en'][i];
  const newSport = sports[locale][i];

  if(i === sports['en'].length - 1) {
    i = 0;
  } else {
    i += 1;
  }

  setNewVideo(sportEn);
  if (locale === 'en') {
    setTimeout(() => {
      sport.textContent = newSport.replace(/_/g, ' ');
    }, 500); // time to allow opacity to hit 0 before changing word
  }
}

const setNewVideo = (sport) => {
  var video = document.getElementById('cover');
  var sources = video.getElementsByTagName('source');
  for (var i = 0; i < sources.length; i++) {
    sources[i].setAttribute('src', videoUrls[sport]);
  }
  video.load();
  video.play();
}

const getAnimationTime = () => {
  if (document.getElementById('cover') === null) {
    return;
  }
  const sport = document.querySelector('.sports');
  const compStyles = window.getComputedStyle(sport);
  let animation = compStyles.getPropertyValue('animation');

  // firefox support for non-shorthand property
  animation != "" ? animation : animation = compStyles.getPropertyValue('-moz-animation-duration');

    // webkit support for non-shorthand property
  animation != "" ? animation : animation = compStyles.getPropertyValue('-webkit-animation-duration');


  const animationTime = parseFloat(animation.match(/\d*[.]?\d+/)) * 1000;
  return animationTime;
}

if (locale === 'en') {
  setNewSport();
  setInterval(setNewSport, getAnimationTime());
}